<template>
  <div>
    <mHeader @change="onSelectChange"></mHeader>
   
    <div class="content">
         <div class="headbox">
            <div class="tips">
            <!-- <div class="lock">锁定中</div>
            <div class="finish">已销售</div> -->
            <div class="intro">
              <div>展位数量: {{zwLength}}个 <span>(已售:{{sellAreaLength}} 个)</span></div>
              <!-- <div>已售数量:<span>{{sellAreaLength}}</span>个</div> -->
              <br/>
              <div>展位总面积: {{zwArea.toFixed(0)}}m² <span>(已售:{{sellArea.toFixed(0)}}m²)</span></div>
              <br/>
              <div>售出比例: <span>{{(sellArea/zwArea*100).toFixed(2)}}%</span></div>
               <!-- <div>展位总面积：<span>{{zwArea}}m²</span></div> -->
            </div>
            </div>
            
            <div class="toolbox">
            <div class="edit" @click="download" v-if="isAdmin" style="padding:2px 10px; font-weight: bold; border:1px solid #409EFF; border-radius: 10px;">点击下载</div>
            </div>
      </div>
      <a id="link"></a>
      <canvas ref="can" id="mobileCanvas" ></canvas>

      <Shuiyin></Shuiyin>

      

      <!--导出-->
      <!-- <PopupExport v-model:exportVisible="exportVisible"></PopupExport> -->

    </div>
    <!-- <footer></footer> -->
  </div>
</template>

<script>
import axios from 'axios';
import { ref, reactive, computed, watch, toRaw} from 'vue'
import {useStore} from 'vuex'
// import {useRouter} from 'vue-router'
// import {useGetMapStyle} from '@/use/diy-use.js'

import mHeader from "./components/mHeader.vue";
// import hallbox from "./components/hallbox.vue";
// import buyrect from "./components/buyrect.vue";
import PopupExport from "./popupExport/popupExport.vue";  //导出展位
import Shuiyin from "@/components/Shuiyin.vue";
import store from '@/store'
import {useGetMapStyle,loadImage,getBackgroundByIndex,getAreaImgByIndex} from '@/use/diy-use.js'

export default {
   name: 'Vue',
   // components:{mHeader,hallbox,buyrect,PopupExport,addRect},
   components:{mHeader,PopupExport,Shuiyin},
   // components:{mHeader,hallbox,buyrect,PopupExport},
   setup(){
      const isAdmin = ref(true);
      const switchValue = ref(true);
      const store = useStore();
      const lists = computed(() => store.state.lists);
      const selectCategory = computed(() => store.state.selectCategory);
      // const rulerIsShow = computed(() => store.state.rulerIsShow);
      const editVisible =ref(false);
      const exportVisible =ref(false);

      const area = ref(0); //展馆总面积
      const zwArea = ref(0);  //展位总面积
      const zwLength = ref(0); //展位个数

      const sellArea = ref(0) //卖出的展位面积
      const sellAreaLength = ref(0) //卖出的展位数量
   
      watch(lists, (newValue, oldValue) => {
         zwArea.value = 0;
         sellArea.value = 0;
         sellAreaLength.value = 0;
         zwLength.value = lists.value.length;
         area.value = Math.floor(selectCategory.value.width*selectCategory.value.height/1000000)
         lists.value.map((rect)=>{
            zwArea.value+=(rect.width*rect.height)/1000000
            if(rect.status===2){
               sellArea.value+=(rect.width*rect.height)/1000000;
               sellAreaLength.value++;
            }
         })
      })

      const onSelectChange = (id)=>{
         store.dispatch("getLists",id);
         store.dispatch("getLineLists",id);
      }

      const openEdit = ()=>{
         editVisible.value=true;
      }
      const openExport = ()=>{
         console.log(555)
         exportVisible.value=true;
      }

      const rulerSwitch = ()=>{
         switchValue.value = switchValue.value?false:true;
         store.commit("setRulerIsShow",switchValue.value);
      }

      return {
         lists,
         isAdmin,
         switchValue,
         rulerSwitch,
         onSelectChange,
         openEdit,
         openExport,
         exportVisible,
         editVisible,
         zwLength,
         area,
         zwArea,
         sellArea,
         sellAreaLength
      }
   },
   data() {
      return {
         options:{
          brand:true,
          bg:true,
          status:true,
          ruler:true,
          grid:false
        },
        canvas: null,
        // cpadding:[350,240,120,240],
        cpadding:[120,120,120,120],
        // cpadding:[0,0,0,0],
        ctx:null,
        innerTop:0,
        innerLeft:0,
        innerWidth:0, //去掉左右边距，去掉背景图内边距，绘图区域显示像素
        innerHeight:0,
        height:'',
        show : false,
        mapStyle:{},
        distW:[], //用于放横向展位间距
        distH:[], //用于放纵向展位间距
      };
    },
    watch:{
      // exportVisible(val){
      //   console.log(this.selectCategory)
      //   this.show = val;
      //   this.$nextTick(()=>{
      //     this.initCalc();
      //   })
      // },
      lists(val){
        console.log(this.selectCategory)
        this.$nextTick(()=>{
          this.initCalc();
        });
      }
    },
    mounted(){
      this.initCalc();
    },
    computed:{
      lists(){return this.$store.state.lists},
      lineLists(){return this.$store.state.lineLists},
      selectCategory(){return this.$store.state.selectCategory},
      isAdmin(){return (store.state.user.role_id===1||store.state.user.role_id===2)}
    },
    methods:{
      initCalc(){
          this.mapStyle = useGetMapStyle(this.$store.state.selectCategory);
          if(!this.canvas){
            this.canvas = document.getElementById("mobileCanvas");
            this.ctx = this.canvas.getContext('2d');
          }
          const {canvas} = this;
          const winW = window.innerWidth;
          const winH = window.innerHeight;
          const widthA4 = 3508; //a4纸长边分辨率
          const cp = this.cpadding;

          // let {width,height} = this.mapStyle.outer;
          let width,height;

          //带背景图片
          if(this.options.bg){
            width = this.mapStyle.outer.width;
            height = this.mapStyle.outer.height;
          }else{
            width = this.mapStyle.inner.width;
            height = this.mapStyle.inner.height;
          }
          const hwRadio = height.replace('px','')/width.replace('px','');

          canvas.width = widthA4+cp[1]+cp[3];
          canvas.height = (height.replace('px','')/width.replace('px','')*widthA4)+cp[0]+cp[2];

          let radio = widthA4/this.mapStyle.outer.width.replace("px",'');

          if(this.options.bg){
            const {top,left,width,height} = this.mapStyle.inner;
            this.innerTop = top.replace("px",'')*radio;
            this.innerLeft = left.replace("px",'')*radio;
            this.innerWidth = width.replace("px",'')*radio; 
            this.innerHeight = height.replace("px",'')*radio; 
          }else{
            this.innerTop = 0;
            this.innerLeft = 0;
            this.innerWidth = canvas.width-cp[1]-cp[3];
            this.innerHeight = canvas.height-cp[0]-cp[2]; 
          }
          this.distW = [];
          this.distH = [];
          this.draw();
      },
      async draw(){
        const {canvas,ctx} = this;
        const {picture,hall_number} = this.selectCategory;
        const cp = this.cpadding;
        ctx.fillStyle="#ffffff";
        ctx.fillRect(0,0,canvas.width, canvas.height);

        if(this.options.bg&&picture!=="http://saleadmin.uniceramics.com.cn/storage/showroom_category/"){
          
          let bgurl = getBackgroundByIndex(hall_number*1);
          // let bgurl = picture;

          let bgImgObj = await loadImage(bgurl);
          ctx.drawImage(bgImgObj, 0, 0, bgImgObj.width, bgImgObj.height, cp[3], cp[0], canvas.width-cp[1]-cp[3], canvas.height-cp[0]-cp[2]);
          this.options.grid && this.drawGrid();
          this.drawRect();
         //  this.drawTitle();
          this.options.ruler && this.drawPaddingRuler();

          // img.src = picture;
        }else{
          this.options.bg = false;
          this.options.grid && this.drawGrid();
          ctx.beginPath();
          ctx.lineWidth="3";
          ctx.strokeStyle="#000000";
          ctx.rect(cp[3], cp[0], canvas.width-cp[1]-cp[3], canvas.height-cp[0]-cp[2]);
          ctx.stroke();
         //  this.drawTitle();
          this.drawRect();
          this.options.ruler && this.drawPaddingRuler();
        }
      },
      async drawTitle(){
        const {canvas,ctx} = this;
        const {name, width, height,picture} = this.selectCategory;
        const cp = this.cpadding;
        // console.log(this.selectCategory);
        const leftRight = this.options.bg?cp[3]+80:cp[3]; //文字左右边距
        const area = Math.floor(width*height/1000000*10)/10+"㎡";

        let logoImgObj = await loadImage(require('@/assets/logo_text.png'));
        ctx.drawImage(logoImgObj, 195, 185, 820, 205);

        ctx.beginPath();
        ctx.moveTo(1156,380);
        ctx.lineTo(3400,380);
        ctx.lineWidth = 5;
        ctx.strokeStyle = "#111";
        ctx.stroke();

        ctx.fillStyle="#fe0000";
        ctx.font = "90px Microsoft YaHei"
        const nameWidth = ctx.measureText(name);
        ctx.fillRect(1160, 200, nameWidth.width+70, 90+70);
        ctx.fillStyle="#fff";
        ctx.textBaseline = 'top';
        ctx.fillText(name,1160+nameWidth.width/2+35,200+44);
        let areaImgObj = await loadImage(getAreaImgByIndex(store.state.selectIndex*1));
        ctx.drawImage(areaImgObj, 3518, 140, 248, 247);
      },
      drawRect(){
         const {canvas,ctx} = this;
         const cp = this.cpadding;
         const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例

         this.lineLists.map((item)=>{
            if(item.direction==='hor'){
                this.distW.push(item)
            }else{
                this.distH.push(item)
            }
        })
         
       
         this.lists.forEach((item)=>{
          // this.getDist(item); //获取展位间的标尺
          const {width,height,top,left,status,title} = item;
          ctx.beginPath();
          ctx.lineWidth="4";
          ctx.strokeStyle="#ed1f24";
          let rleft = left*radio+this.innerLeft+cp[3];
          let rtop = top*radio+this.innerTop+cp[0];
          let rheight = height*radio;
          let rwidth = width*radio;
          ctx.rect(rleft, rtop, rwidth, rheight);
          ctx.stroke();
          // console.log(item);

          if(this.selectCategory.code!==11&&this.selectCategory.code!==12){
            this.options.ruler && this.drawRuler(item);
          }

          ctx.fillStyle="#fff";
          ctx.fillRect(rleft, rtop, rwidth, rheight);
          
          //已经完成
          if(this.options.status&&item.status===2){
            ctx.fillStyle="#ffadad";
            ctx.fillRect(rleft, rtop, rwidth, rheight);
          }
          //锁定中
          if(this.options.status&&item.status===3){
            ctx.fillStyle="#dddddd";
            ctx.fillRect(rleft, rtop, rwidth, rheight);
          }


          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          //长条形展位
          if(height<=4000&&width>20000){
            ctx.font = "28px bold Arial";
            ctx.fillStyle = "#000";
            let text = title+ "　" +width/1000+"×"+height/1000+"="+width*height/1000000;
            if(this.options.status && !!item.order_form.customer_brand){
              let maxFontSize = Math.floor(rwidth/28)-text.length;
              let company = item.order_form.customer_brand
              let subtext = company.length<maxFontSize?company:company.substr(0,maxFontSize-1)+'...';
              text = title+ "　" + subtext + "　" + width/1000+"×"+height/1000+"="+width*height/1000000;
            }
            ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            return false;
          }

          //长条形展位  竖向
          if(width<=4000&&height>20000){
            ctx.font = "28px bold Arial";
            ctx.fillStyle = "#000";
            let text = title+ "　" +width/1000+"×"+height/1000+"="+width*height/1000000;
            if(this.options.status && this.options.brand && !!item.order_form.customer_brand){
              let maxFontSize = Math.floor(rheight/28)-text.length;
              let company = item.order_form.customer_brand
              let subtext = company.length<maxFontSize?company:company.substr(0,maxFontSize-1)+'...';
              text = title+ "　" + subtext + "　" + width/1000+"×"+height/1000+"="+width*height/1000000;
            }
            // ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            ctx.save();
            ctx.translate(rleft+rwidth/2,rtop+rheight/2);
            ctx.rotate(Math.PI/180*90);
            ctx.textBaseline = 'middle';
            ctx.fillText(text,0,0);
            ctx.restore();
            // y+=ctx.measureText(str).width+letterSpacing;
            // ctx.rotate(Math.PI/180*90);
            ctx.restore();
            return false;
          }

          //宽高都大于6m的展位
          if(height>6000&&width>6000){
            let offset = 30;
            if(this.options.status && this.options.brand && !!item.order_form.customer_brand){
              offset = 50
              ctx.font = "bold 30px Arial";
              ctx.fillStyle = "#333";
              let text = this.splitText(item.order_form.customer_brand, rwidth, 30);
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            }
            ctx.font = "bold 40px Arial";
            ctx.fillStyle = "#000";
            ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset+2);
            ctx.font = "bold 30px Arial";
            ctx.fillStyle = "#333";
            ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset);
            return false;
          }

          //宽度为3米，高度小于20m的小展位
          if(height<20000&&height>60000&&width===3000){
            let offset = 20;
            if(this.options.status && this.options.brand && !!item.order_form.customer_brand){
              offset = 35
              ctx.font = "bold 18px Arial";
              ctx.fillStyle = "#333";
              let text = this.splitText(item.order_form.customer_brand, rwidth, 18);
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            }
            ctx.font = "bold 20px Arial";
            ctx.fillStyle = "#000";
            ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset);
            ctx.font = "bold 18px Arial";
            ctx.fillStyle = "#333";
            ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset+4);
            return false;
          }

          if(height<=4000&&width<=4000){
            let offset = 10;
            ctx.font = "bold 18px Arial";
             if(this.selectCategory.width>140000){
              ctx.font = "bold 18px Arial";
            }
            ctx.fillStyle = "#000";
            ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset);
            ctx.font = "bold 18px Arial";
            ctx.fillStyle = "#333";
            ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset+5);
            return false;
          }

          // 高度或宽度小于6m的中小型展位
          if(height<=6000||width<=6000){
            let offset = 16;
            if(this.options.status && !!item.order_form.customer_brand){
              offset = 24;
              ctx.font = "bold 20px Arial";
              ctx.fillStyle = "#333";
              let text = this.splitText(item.order_form.customer_brand, rwidth, 20);
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            }
            ctx.font = "bold 20px Arial";
             if(this.selectCategory.width>140000){
              ctx.font = "bold 18px Arial";
            }
            ctx.fillStyle = "#000";
            ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset);
            ctx.font = "bold 18px Arial";
            ctx.fillStyle = "#333";
            ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset);
            return false;
          }
         })

         this.drawDist();
      },
      getDist(item){
        let itemRight = item.left+item.width;
        let itemTop = item.top;
        let itemLeft = item.left;
        let itemBottom = itemTop+item.height;
        let minDist = null;
        let minHDist = null;
        
        const radio = this.canvas.width/this.selectCategory.width;
        const height = this.innerHeight;

        // console.log(height);

        this.lists.forEach((item2)=>{
            //检测水平位置展位间距
            if(Math.abs(itemTop-item2.top)<50 && item2.left >= itemRight){
                minDist === null && (minDist = item2.left - itemRight);
                if(item2.left - itemRight<minDist){
                    minDist = item2.left - itemRight;
                }
            }
            //检测垂直方向展位距离
            if(Math.abs(itemLeft-item2.left)<50 && item2.top >= itemBottom){
                minHDist === null && (minHDist = item2.top - itemBottom);
                
                if(item2.top - itemBottom<minHDist){
                    minHDist = item2.top - itemBottom;
                }
            }
        })
        if(minDist!==null&&minDist<12000&&minDist>2000){
            // distW.push({top:itemTop,left:itemRight,width:minDist});
            let rtop = Math.round(item.top*radio);
            let bool = this.distW.findIndex((item)=>{
               return item.left===itemRight && item.width === minDist &&  Math.abs(item.top - itemTop)<30000
            })
            if(rtop<height/2 &&item.title!=='A7-08'&&item.title!=='A10-06'&&item.title!=='A10-08'&&item.title!=='A9-52'&&item.title!=='A10-15'&&item.title!=='702'&&item.title!=='605'){
               bool===-1 &&this.distW.push({top:itemTop+item.height,left:itemRight,width:minDist});
            }
            if(rtop>height/2 && item.title!=='650' ){
               bool===-1 &&this.distW.push({top:itemTop,left:itemRight,width:minDist});
            }
        }
        //竖向标尺
        if(minHDist!==null&&minHDist<12000&&minHDist>2000){
            let bool = this.distH.findIndex((item)=>{
                return item.top===itemBottom && item.height === minHDist &&  Math.abs(item.left - itemLeft)<50000
            })
            if(item.title!=='623'){
                bool===-1 && this.distH.push({top:itemBottom,left:itemLeft,height:minHDist});
            }
        }
      },
      drawDist(){
         function isIntersect(lineSegment1StartX, lineSegment1StartY, lineSegment1EndX, lineSegment1EndY, lineSegment2StartX, lineSegment2StartY, lineSegment2EndX, lineSegment2EndY) {
            // 判断第一条线段的起点和终点是否在第二条线段内部或重合
            if (isPointInsideLineSegment(lineSegment2StartX, lineSegment2StartY, lineSegment1StartX, lineSegment1StartY, lineSegment1EndX, lineSegment1EndY)) return true;
            if (isPointInsideLineSegment(lineSegment2EndX, lineSegment2EndY, lineSegment1StartX, lineSegment1StartY, lineSegment1EndX, lineSegment1EndY)) return true;
            // 判断第二条线段的起点和终点是否在第一条线段内部或重合
            if (isPointInsideLineSegment(lineSegment1StartX, lineSegment1StartY, lineSegment2StartX, lineSegment2StartY, lineSegment2EndX, lineSegment2EndY)) return true;
            if (isPointInsideLineSegment(lineSegment1EndX, lineSegment1EndY, lineSegment2StartX, lineSegment2StartY, lineSegment2EndX, lineSegment2EndY)) return true;
            // 若以上两种情况都未发生，则返回false表示两条线段不相交
            return false;
         }
 
         // 判断一个点是否在直线段内部或重合
         function isPointInsideLineSegment(pointX, pointY, segmentStartX, segmentStartY, segmentEndX, segmentEndY) {
            var dx = segmentEndX - segmentStartX;
            var dy = segmentEndY - segmentStartY;
            var t = ((pointX - segmentStartX) * dx + (pointY - segmentStartY) * dy) / (dx * dx + dy * dy);
         
            if (t < 0 || t > 1) {
               return false;
            } else {
               var x = segmentStartX + t * dx;
               var y = segmentStartY + t * dy;
               
               return Math.abs(x - pointX) <= Number.EPSILON && Math.abs(y - pointY) <= Number.EPSILON;
            }
         }

         //横向标尺和纵向标尺检测
         function checkCross(x, y, x1, y1, lists){
            let bool = true;
            for(let i=0; i<lists.length; i++){
               if(isIntersect(x, y, x1, y1, lists[i].left, lists[i].top, lists[i].left, lists[i].top+lists[i].height)){
               // console.log("交叉")
               bool = false;
               break;
               }
            }
            // console.log(bool);
            return bool;
         }
         // 纵向标尺和矩形标尺检测是否有交叉，纵向标尺，此处和矩形横向线段检测
         function checkCrossVsRect(x, y, x1, y1, lists, radio, height){
            let bool = true;
            console.log(lists)
            lists.forEach((item)=>{
               let rtop = Math.round(item.top*radio);
               if(rtop<height/2){
                     //上半部分展位，标尺在下方
                     if(isIntersect(x-800, y, x1-800, y1, item.left, item.top+item.height+0, item.left+item.width, item.top+item.height+0)){
                        bool = false;
                        console.log("上交叉")
                     }
               }
               if(rtop>height/2){
                     // console.log(x, y, x1, y1, item.left, item.top-800, item.left+item.width, item.top-800)
                     //下半部分展位，标尺在上方
                     if(isIntersect(x-800, y, x1-800, y1, item.left, item.top-0, item.left+item.width, item.top-0)){
                        bool = false;
                        console.log("下交叉")
                     }
               }
               
            })
            return bool;
         }


         const cp = this.cpadding;
         const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
         const height = this.innerHeight;

         const {ctx} = this;
         let offset = 20;
         if(this.options.grid){
            offset=1000*radio;
         }
         ctx.beginPath();
         ctx.lineWidth = 1;
         ctx.strokeStyle = '#666';
         ctx.font = "22px bold Arial";
         ctx.fillStyle = "#333";
         ctx.textBaseline = 'middle';
         this.distH.forEach((item)=>{
            // console.log(item)
            let rleft = Math.round(item.left*radio+this.innerLeft+cp[3]);
            let rtop = Math.round(item.top*radio+this.innerTop+cp[0]);
            let rheight = Math.round(item.width*radio);
            // if(checkCrossVsRect(item.left,item.top, item.left, item.top+item.height, this.lists, radio, height)){
               //画左侧标尺
               ctx.save(); 
               ctx.translate(0.5,0.5);
               ctx.beginPath();
               ctx.moveTo(rleft-offset,rtop);
               ctx.lineTo(rleft-offset,rtop+rheight);
               ctx.moveTo(rleft-offset-5, rtop);
               ctx.lineTo(rleft-5, rtop);
               ctx.moveTo(rleft-offset-5, rtop+rheight);
               ctx.lineTo(rleft-5, rtop+rheight);
               ctx.stroke();
               ctx.restore();
               // 画箭头
               ctx.moveTo(rleft-offset-4,rtop-4);
               ctx.lineTo(rleft-offset+4,rtop+4);
               ctx.moveTo(rleft-offset-4,rtop+rheight-4);
               ctx.lineTo(rleft-offset+4,rtop+rheight+4);
               ctx.stroke();
               //画文字
               ctx.save();
               ctx.translate(rleft-offset-15,rtop+rheight/2);
               ctx.rotate(Math.PI/2);
               ctx.fillText(item.width,0,0);
               ctx.restore();
            // }
         })

         this.distW.forEach((item)=>{
            let rleft = Math.round(item.left*radio+this.innerLeft+cp[3]);
            let rtop = Math.round(item.top*radio+this.innerTop+cp[0]);
            let rwidth = Math.round(item.width*radio);
            // let rheight = Math.round(item.height*radio);
            let rheight = 0;
            if(item.width>1000&&item.top*radio<this.innerHeight/2){
               // console.log(title+"----上半部分展位");
               //画下面侧标尺
               ctx.save();
               ctx.translate(0.5,0.5);
               ctx.beginPath();
               ctx.moveTo(rleft,rtop+rheight+offset);
               ctx.lineTo(rleft+rwidth, rtop+rheight+offset);
               ctx.moveTo(rleft,rtop+rheight+5);
               ctx.lineTo(rleft, rtop+rheight+offset+5);
               ctx.moveTo(rleft+rwidth,rtop+rheight+5);
               ctx.lineTo(rleft+rwidth, rtop+rheight+offset+5);
               ctx.stroke();
               // 画箭头
               ctx.moveTo(rleft+4,rtop+rheight+offset-4);
               ctx.lineTo(rleft-4,rtop+rheight+offset+4);
               ctx.moveTo(rleft+rwidth+4, rtop+rheight+offset-4);
               ctx.lineTo(rleft+rwidth-4, rtop+rheight+offset+4);
               ctx.stroke();
               ctx.restore();
               //画文字
               ctx.fillText(item.width,rleft+rwidth/2,rtop+rheight+offset+16);
            }

            if(item.width>1000&&item.top*radio>this.innerHeight/2){
               // console.log(title+"----下半部分展位");
               //画下面侧标尺
               ctx.save();
               ctx.translate(0.5,0.5);
               ctx.beginPath();
               ctx.moveTo(rleft,rtop-offset);
               ctx.lineTo(rleft+rwidth, rtop-offset);
               ctx.moveTo(rleft,rtop-5);
               ctx.lineTo(rleft, rtop-offset-5);
               ctx.moveTo(rleft+rwidth,rtop-5);
               ctx.lineTo(rleft+rwidth, rtop-offset-5);
               ctx.stroke();
               ctx.restore();
               // 画箭头
               ctx.moveTo(rleft+4,rtop-offset-4);
               ctx.lineTo(rleft-4,rtop-offset+4);
               ctx.moveTo(rleft+rwidth+4, rtop-offset-4);
               ctx.lineTo(rleft+rwidth-4, rtop-offset+4);
               ctx.stroke();
               //画文字
               ctx.fillText(item.width,rleft+rwidth/2,rtop-offset-12);
            }
         })



      },
      drawRuler(rect){
        const {canvas,ctx} = this;
        const {width,height,top,left,status,title} = rect;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;

        let rleft = Math.round(left*radio+this.innerLeft+cp[3]);
        let rtop = Math.round(top*radio+this.innerTop+cp[0]);
        let rheight = Math.round(height*radio);
        let rwidth = Math.round(width*radio);

        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#666';
        ctx.font = "22px bold Arial";
        ctx.fillStyle = "#333";
        ctx.textBaseline = 'middle';
       
        //画左侧标尺
        // console.log(rleft-20,rtop,rleft,rtop+rheight) //矩形左上角，右下角顶点
        let offset = 20;
        if(this.options.grid){
          offset=1000*radio;
        }
        if(rect.height>3000&&this.checkCross({left:rleft-21, top:rtop, width:20, height: rheight})){
          ctx.save(); 
          ctx.translate(0.5,0.5);
          ctx.beginPath();
          ctx.moveTo(rleft-offset,rtop);
          ctx.lineTo(rleft-offset,rtop+rheight);
          ctx.moveTo(rleft-offset-5, rtop);
          ctx.lineTo(rleft-5, rtop);
          ctx.moveTo(rleft-offset-5, rtop+rheight);
          ctx.lineTo(rleft-5, rtop+rheight);
          ctx.stroke();
          ctx.restore();
          // 画箭头
          ctx.moveTo(rleft-offset-4,rtop-4);
          ctx.lineTo(rleft-offset+4,rtop+4);
          ctx.moveTo(rleft-offset-4,rtop+rheight-4);
          ctx.lineTo(rleft-offset+4,rtop+rheight+4);
          ctx.stroke();
          //画文字
          ctx.save();
          ctx.translate(rleft-offset-15,rtop+rheight/2);
          ctx.rotate(Math.PI/2);
          ctx.fillText(height,0,0);
          ctx.restore();
        }
        

         if(rect.width>3000&&top*radio<this.innerHeight/2 && this.checkCross({left:rleft, top:rtop+rheight+5, width:rwidth, height: 20})){
            // console.log(title+"----上半部分展位");
            //画下面侧标尺
            ctx.save();
            ctx.translate(0.5,0.5);
            ctx.beginPath();
            ctx.moveTo(rleft,rtop+rheight+offset);
            ctx.lineTo(rleft+rwidth, rtop+rheight+offset);
            ctx.moveTo(rleft,rtop+rheight+5);
            ctx.lineTo(rleft, rtop+rheight+offset+5);
            ctx.moveTo(rleft+rwidth,rtop+rheight+5);
            ctx.lineTo(rleft+rwidth, rtop+rheight+offset+5);
            ctx.stroke();
            
            // 画箭头
            ctx.moveTo(rleft+4,rtop+rheight+offset-4);
            ctx.lineTo(rleft-4,rtop+rheight+offset+4);
            ctx.moveTo(rleft+rwidth+4, rtop+rheight+offset-4);
            ctx.lineTo(rleft+rwidth-4, rtop+rheight+offset+4);
            ctx.stroke();
            ctx.restore();
            //画文字
            ctx.fillText(width,rleft+rwidth/2,rtop+rheight+offset+16);
  
         }

         if(rect.width>3000&&top*radio>this.innerHeight/2 && this.checkCross({left:rleft, top:rtop-offset-10, width:rwidth, height: 20})){
            // console.log(title+"----下半部分展位");
            //画下面侧标尺
            ctx.save();
            ctx.translate(0.5,0.5);
            ctx.beginPath();
            ctx.moveTo(rleft,rtop-offset);
            ctx.lineTo(rleft+rwidth, rtop-offset);
            ctx.moveTo(rleft,rtop-5);
            ctx.lineTo(rleft, rtop-offset-5);
            ctx.moveTo(rleft+rwidth,rtop-5);
            ctx.lineTo(rleft+rwidth, rtop-offset-5);
            ctx.stroke();
            ctx.restore();
            // 画箭头
            ctx.moveTo(rleft+4,rtop-offset-4);
            ctx.lineTo(rleft-4,rtop-offset+4);
            ctx.moveTo(rleft+rwidth+4, rtop-offset-4);
            ctx.lineTo(rleft+rwidth-4, rtop-offset+4);
            ctx.stroke();
            //画文字
            ctx.fillText(width,rleft+rwidth/2,rtop-offset-12);
         }

      },
      checkCross(rect){
        let bool = true;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;

        for(let i=0; i<this.lists.length; i++){
          const {width,height,top,left,status,title} = this.lists[i];
          let r = {
            left:left*radio+this.innerLeft+cp[3],
            top:top*radio+this.innerTop+cp[0],
            height:height*radio,
            width:width*radio
          }
          if(this.isCross(rect,r)){
            bool = false;
            break;
          }
        }
        return bool;
      },
      isCross(rect1,rect2){
        let lux = Math.max(rect1.left, rect2.left);
        let luy = Math.max(rect1.top, rect2.top);
        let rdx = Math.min(rect1.left+rect1.width, rect2.left+rect2.width);
        let rdy = Math.min(rect1.top+rect1.height, rect2.top+rect2.height);
        // return (lux<rdx && luy<rdy);
        //此处允许有1个像素误差，某些展位顶点有重合，不认为展位有重合
        return (lux+1<rdx && luy+1<rdy); 
      },
      drawPaddingRuler(){
        const {canvas,ctx} = this;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;
        const org = {x:cp[3]+this.innerLeft, y:cp[0]+this.innerTop};

        //根据展位内边距计算边距标尺
        const sumWidth = this.selectCategory.width*radio;
        const sumHeight = this.selectCategory.height*radio;
        const top = this.selectCategory.top_padding*radio;
        const bottom = this.selectCategory.bottom_padding*radio;
        const left = this.selectCategory.left_padding*radio;
        const right = this.selectCategory.right_padding*radio;
        const width = sumWidth - left -right;
        const height = sumHeight -top -bottom;

        if(top===0&&left===0){
          return false;
        }

        ctx.font = "22px bold Arial";
        ctx.fillStyle = "#333";
        ctx.strokeStyle = '#666';
        ctx.textBaseline = 'top';
        ctx.textAlign = "center";
        // ctx.setLineDash([3]);
        ctx.translate(0.5,0.5);
        ctx.beginPath();

        /* 横向 */
        //左上
        ctx.moveTo(org.x, org.y+top);
        ctx.lineTo(org.x+left, org.y+top);
        ctx.fillText(this.selectCategory.left_padding, org.x+left/2, org.y+top+5)
        // 画箭头
        ctx.moveTo(org.x+4, org.y+top-4);
        ctx.lineTo(org.x-4, org.y+top+4);
        ctx.moveTo(org.x+left+4, org.y+top-4);
        ctx.lineTo(org.x+left-4, org.y+top+4);
        ctx.stroke();

        //左下
        ctx.moveTo(org.x, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+left, org.y+sumHeight-bottom);
        ctx.fillText(this.selectCategory.left_padding, org.x+left/2, org.y+sumHeight-bottom-23)
        // 画箭头
        ctx.moveTo(org.x+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x-4, org.y+sumHeight-bottom+4);
        ctx.moveTo(org.x+left+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x+left-4, org.y+sumHeight-bottom+4);
        ctx.stroke();

        // 右上
        ctx.moveTo(org.x+sumWidth-right, org.y+top);
        ctx.lineTo(org.x+sumWidth, org.y+top);
        ctx.fillText(this.selectCategory.right_padding, org.x+sumWidth-right/2, org.y+top+5)
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4, org.y+top-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+top+4);
        ctx.moveTo(org.x+sumWidth+4, org.y+top-4);
        ctx.lineTo(org.x+sumWidth-4, org.y+top+4);
        ctx.stroke();

        // 右下
        ctx.moveTo(org.x+sumWidth-right, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+sumWidth, org.y+sumHeight-bottom);
        ctx.fillText(this.selectCategory.right_padding, org.x+sumWidth-right/2, org.y+sumHeight-bottom-20)
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+sumHeight-bottom+4);
        ctx.moveTo(org.x+sumWidth+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x+sumWidth-4, org.y+sumHeight-bottom+4);
        ctx.stroke();

        /* 纵向 */
        //左上
        ctx.moveTo(org.x+left, org.y);
        ctx.lineTo(org.x+left, org.y+top);
        ctx.save();
        ctx.translate(org.x+left+20, org.y+top/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.top_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+left+4,org.y-4);
        ctx.lineTo(org.x+left-4, org.y+4);
        ctx.stroke();

        //左下
        ctx.moveTo(org.x+left, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+left, org.y+sumHeight);
        ctx.save();
        ctx.translate(org.x+left+23, org.y+sumHeight-bottom/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.top_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+left+4,org.y+sumHeight-4);
        ctx.lineTo(org.x+left-4, org.y+sumHeight+4);
        ctx.stroke();

        //右上
        ctx.moveTo(org.x+sumWidth-right, org.y);
        ctx.lineTo(org.x+sumWidth-right, org.y+top);
        ctx.save();
        ctx.translate(org.x+sumWidth-right-5, org.y+top/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.top_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4,org.y-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+4);
        ctx.stroke();

        //右下
        ctx.moveTo(org.x+sumWidth-right, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+sumWidth-right, org.y+sumHeight);
        ctx.save();
        ctx.translate(org.x+sumWidth-right-5, org.y+sumHeight-bottom/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.bottom_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4, org.y+sumHeight-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+sumHeight+4);
        ctx.stroke();
      },
      drawGrid(){
        // let loadingInstance = Loading.service({
        //   lock: true,
        //   text: 'Loading',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.5)'
        // });

        const {canvas,ctx} = this;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;
        const sumWidth = this.selectCategory.width;
        const sumHeight = this.selectCategory.height;

        const top = this.selectCategory.top_padding;
        const left = this.selectCategory.left_padding;

        const org = {x:cp[3]+this.innerLeft+left%1000*radio, y:cp[0]+this.innerTop+top%1000*radio};

        let w = sumWidth - left%1000;
        let h = sumHeight - top%1000;
        let margin = 1000*radio;
        // ctx.save();
        ctx.translate(0.5,0.5);
        ctx.strokeStyle = '#bbb';
        ctx.setLineDash([2,6]);
        //纵向线
        for(let i = 0; i <= w/1000; i++){
          ctx.moveTo(org.x+i*margin, org.y - top%1000*radio);
          ctx.lineTo(org.x+i*margin, org.y+sumHeight*radio- top%1000*radio);
        }
        //横向线
        for(let j = 0; j <= h/1000; j++){
          ctx.moveTo(org.x - left%1000*radio, org.y+j*margin);
          ctx.lineTo(org.x+sumWidth*radio-left%1000*radio, org.y+j*margin);
        }
        ctx.stroke();
        ctx.setLineDash([0]);
        // ctx.restore();
        // loadingInstance.close();
      },
      bgSwitch(){
        this.initCalc();
      },
      rulerSwitch(){
        this.initCalc();
      },
      gridSwitch(){
        this.initCalc();
      },
      splitText(text, maxwidth, fontsize){
        if(text.length*fontsize<=maxwidth){
          return text;
        }
        return text.substr(0,Math.floor(maxwidth/fontsize)-1)+'...';
      },
      download(){
        const canvas = this.canvas;
        let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); 
    
        let link = document.getElementById('link');
        link.setAttribute('download', this.selectCategory.name+'.png');
        link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
        link.click();
      },
    }
}
</script>

<style scoped>
.content{display: flex; min-height:calc(100vh - 74px); flex-direction: column; justify-content: center; align-items: center; }
footer{ height:60px; background: url(../../assets/footer2.jpg) center no-repeat; background-size:100% 100%;}

.headbox{width:100%; display: flex; justify-content: space-between; margin-top:40px;}
.headbox .tips{margin-bottom:10px; margin-top:10px; display:flex;}
.headbox .tips>div{padding-left:10px; position: relative; font-size:12px; line-height:14px; margin:0 5px; }
.headbox .tips>div:before{content:''; display:block; width:25px; height:12px; border:1px solid #ddd; position: absolute; top:0px; left:0; border-radius: 4px;}
.headbox .tips>div.finish:before{background:#ffadad;}
.headbox .tips>div.lock:before{background:#ddd; }
.headbox .tips>div.choice:before{background:rgba(144,238,144,0.43);}
.headbox .tips>div.normal:before{border:1px solid #ccc;}

.headbox .tips>div.intro{padding-left:10px;}
.headbox .tips>div.intro:before{display: none;}
.headbox .tips>div.intro>div{display:inline; margin-right:10px;}
.headbox .tips>div.intro>div>span{color:red; font-weight: bold;}

.toolbox{display:flex; align-items: center;}
.toolbox .tool{width:20px; height:20px; line-height:20px; margin-left:4px; border:1px dashed #666; text-align: center; cursor:pointer; font-size:15px;}
.toolbox .tool.che{background:rgba(144,238,144,0.43);}
.toolbox .edit{color:#409EFF; margin-right:10px; cursor:pointer;}

#mobileCanvas{width:100%;}

</style>